/**
 * Custom select made of Bootstrap's dropdown component
 * @requires https://getbootstrap.com
 * @requires https://popper.js.org/
 */

export default (() => {
  const selectList = document.querySelectorAll('.form-custom-select')

  if (selectList.length === 0) return

  const handleOptionClick = (e) => {
    const option = e.target.closest('[data-option]')
    if (!option) return

    const select = option.closest('.form-custom-select')
    const hiddenInput = select.querySelector('input.visually-hidden')
    const dropdownToggle = select.querySelector('.form-select')
    const activeOption = select.querySelector('.active[data-option]')

    if (activeOption) {
      activeOption.classList.remove('active')
      activeOption.setAttribute('aria-selected', 'false')
    }

    option.classList.add('active')
    option.setAttribute('aria-selected', 'true')

    hiddenInput.value = option.getAttribute('data-option')
    dropdownToggle.innerText = option.innerText
  }

  const handleClearSearch = (e) => {
    const select = e.target.closest('.form-custom-select')
    const searchInput = select.querySelector('.form-control')
    if (!searchInput) return

    const clearButton = select.querySelector('.form-control + .btn')

    const clickEvent = new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window,
    })

    clearButton.dispatchEvent(clickEvent)
  }

  selectList.forEach((select) => {
    select.addEventListener('click', handleOptionClick)
    select.addEventListener('hidden.bs.dropdown', handleClearSearch)
  })
})()
