/**
 * Filter list of items by typing in the search field
 */

export default (() => {
  const filterListWidget = document.querySelectorAll('.widget-filter')

  filterListWidget.forEach((filterWidget) => {
    const filterInput = filterWidget.querySelector('.widget-filter-search')
    const filterList = filterWidget.querySelector('.widget-filter-list')
    const filterItems = filterList.querySelectorAll('.widget-filter-item')

    if (!filterInput) {
      return
    }

    filterInput.addEventListener('keyup', filterListFunc)

    function filterListFunc() {
      const filterValue = filterInput.value.toLowerCase()

      filterItems.forEach((filterItem) => {
        const filterText = filterItem.querySelector(
          '.widget-filter-item-text'
        ).innerHTML

        if (filterText.toLowerCase().includes(filterValue)) {
          filterItem.classList.remove('d-none')
        } else {
          filterItem.classList.add('d-none')
        }
      })
    }
  })
})()
