/**
 * Rotate on scroll animation
 */

export default (() => {
  function rotateOnScroll(element, intensity) {
    window.addEventListener('scroll', () => {
      const scrollY = window.scrollY
      const rotationDirection = intensity < 0 ? -1 : 1
      const rotation = scrollY * Math.abs(intensity) * rotationDirection
      element.style.transform = `rotate(${rotation}deg)`

      element.classList.add('rotate-animation')

      clearTimeout(element.animationTimeout)
      element.animationTimeout = setTimeout(() => {
        element.classList.remove('rotate-animation')
      }, 500)
    })
  }

  function initRotateOnScroll() {
    const elementsToRotate = document.querySelectorAll('.rotate-on-scroll')

    elementsToRotate.forEach((element) => {
      const rotationIntensity =
        parseFloat(element.getAttribute('data-rotation-intensity')) || 0
      rotateOnScroll(element, rotationIntensity)
    })
  }

  document.addEventListener('DOMContentLoaded', initRotateOnScroll)
})()
